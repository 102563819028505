import React from "react";
import PropTypes from "prop-types";

import LoginForm from "./login_form";
import PasswordForm from "./password_form";
import MagicLinkNotification from "components/login_flow/magic_link_notification";

class LoginFlow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: "",
      magicLinkReturnTo: "",
      showPasswordForm: false,
      showMagicLinkNotification: false,
    };
  }

  showLoginForm = () => {
    this.setState({
      showPasswordForm: false,
      showMagicLinkNotification: false,
    });
  };

  showPasswordForm = (login, saml_path) => {
    this.setState({
      login: login,
      samlPath: saml_path,
      showPasswordForm: true,
    });
  };

  showMagicLinkNotification = (login, returnTo) => {
    this.setState({
      showMagicLinkNotification: true,
      magicLinkReturnTo: returnTo,
      login: login,
    });
  };

  render() {
    if (this.state.showPasswordForm) {
      return (
        <PasswordForm
          login={this.state.login}
          samlPath={this.state.samlPath}
          backCallback={this.showLoginForm}
          forgot_password_path={this.props.forgot_password_path}
          sign_in_path={this.props.sign_in_path}
        />
      );
    } else if (this.state.showMagicLinkNotification) {
      return (
        <MagicLinkNotification
          backCallback={this.showLoginForm}
          login={this.state.login}
          magicLinksPath={this.props.magic_links_path}
          magicLinkReturnTo={this.state.magicLinkReturnTo}
        />
      );
    } else {
      return (
        <LoginForm
          showPasswordForm={this.showPasswordForm}
          magicLinkNotificationCallback={this.showMagicLinkNotification}
          sign_in_with_google_path={this.props.sign_in_with_google_path}
          validate_login_path={this.props.validate_login_path}
          login={this.state.login}
        />
      );
    }
  }
}

LoginFlow.propTypes = {
  forgot_password_path: PropTypes.string.isRequired,
  sign_in_path: PropTypes.string.isRequired,
  sign_in_with_google_path: PropTypes.string.isRequired,
  validate_login_path: PropTypes.string.isRequired,
  magic_links_path: PropTypes.string.isRequired,
};

export default LoginFlow;
