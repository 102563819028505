import { configurationData } from "utilities/configuration_data";

const companyFeatureFlags = configurationData().companyFeatureFlags;

// Methods for interacting with feature flags from the backend for the current company.
//
// Note: all feature flag keys should be referenced exactly as they are defined on the backend.
class FeatureFlags {
  static all() {
    return companyFeatureFlags;
  }

  static allKeys() {
    return Object.keys(companyFeatureFlags);
  }

  static active(key) {
    return !!companyFeatureFlags[key];
  }
}

export default FeatureFlags;
