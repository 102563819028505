import React from "react";
import { Button, ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import { FormContainer, Input, LongerStyledButton } from "./styles";
import HiddenCsrfInput from "./hidden_csrf_input";
import styled from "styled-components";
import PasswordStrengthBar from "@grnhse/seedling/lib/azalea/components/purgatory/password_strength_bar";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordConfirmation: "",
    };
  }

  onChange = (event) => {
    this.setState({
      [event.target.dataset.stateName]: event.target.value.trim(),
    });
  };

  getButtonState = () => {
    return (
      this.state.password.length === 0 ||
      this.state.passwordConfirmation.length === 0
    );
  };

  render() {
    return (
      <FormContainer id="reset-password">
        <h2>Reset your password</h2>
        <form action={this.props.form_action_path} method="post">
          <HiddenCsrfInput />
          <input name="_method" type="hidden" value="put" />
          <input
            name="user[reset_password_token]"
            type="hidden"
            value={this.props.reset_password_token}
          />

          <FormGroup controlId="email" bsSize="large">
            <Input
              type="password"
              placeholder="New Password"
              aria-label="New Password"
              value={this.state.email}
              onChange={this.onChange}
              inputRef={(ref) => {
                this.input = ref;
              }}
              name="user[password]"
              data-state-name="password"
              autoFocus
              required
            />
            <StyledBar>
              <PasswordStrengthBar password={this.state.password} />
            </StyledBar>
          </FormGroup>

          <FormGroup controlId="email" bsSize="large">
            <Input
              type="password"
              placeholder="Confirm New Password"
              aria-label="Confirm New Password"
              value={this.state.email}
              onChange={this.onChange}
              name="user[password_confirmation]"
              data-state-name="passwordConfirmation"
              required
            />
          </FormGroup>

          <FormGroup>
            <LongerStyledButton
              type="submit"
              bsSize="large"
              bsStyle="primary"
              block
              disabled={this.getButtonState()}
            >
              Update password
            </LongerStyledButton>
          </FormGroup>
        </form>
      </FormContainer>
    );
  }
}

const StyledBar = styled.div`
  margin: 0 auto;
  width: 98%;
`;

ResetPassword.propTypes = {
  form_action_path: PropTypes.string.isRequired,
  reset_password_token: PropTypes.string.isRequired,
};

export default ResetPassword;
