class Csrf {
  static token() {
    const csrfTokenElement = document.getElementsByName("csrf-token");

    return csrfTokenElement && csrfTokenElement.length > 0
      ? csrfTokenElement[0].content
      : null;
  }
}

export default Csrf;
