import ReactMounter from "../utilities/react_mounter";

import LoginFlow from "../components/login_flow/login_flow";
import ForgotPassword from "../components/login_flow/forgot_password";
import ResetPassword from "../components/login_flow/reset_password";
import SetupPassword from "../components/login_flow/setup_password";
import { Flash } from "../components/shared/flash";

new ReactMounter(LoginFlow, "LoginFlow").init();
new ReactMounter(ForgotPassword, "ForgotPassword").init();
new ReactMounter(ResetPassword, "ResetPassword").init();
new ReactMounter(SetupPassword, "SetupPassword").init();
new ReactMounter(Flash, "Flash").init();
