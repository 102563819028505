import React, { createElement } from "react";
import { render } from "react-dom";
import { ThemeProvider } from "styled-components";
import FeatureFlags from "utilities/feature_flags";
import PageDataStore from "./page_data_store";

let ReactMounter = function (component, name) {
  this.component = component;
  this.name = name;
};

ReactMounter.prototype.init = function () {
  PageDataStore.data.then((data) => {
    window.greenhouseOnboarding = window.greenhouseOnboarding || {};
    window.greenhouseOnboarding.departmentFieldName =
      data.department_field_name;
    window.greenhouseOnboarding.locationFieldName = data.location_field_name;
    window.greenhouseOnboarding.employmentStatusFieldName =
      data.employment_status_field_name;
    window.greenhouseOnboarding.bulkActionPollerPath =
      data.bulk_action_poller_path;
    window.greenhouseOnboarding.bulkExportPollerPath =
      data.bulk_export_poller_path;
    window.greenhouseOnboarding.currentEmployeeId = data.current_employee_id;

    const nodeList = document.querySelectorAll(
      `[data-react-class=${this.name}]`
    );
    const elements = Array.prototype.slice.call(nodeList); // Convert from nodelist to array.

    elements.map((elem) => {
      const className = elem.getAttribute("data-react-class");

      let props = {};
      // Add properties specific to component
      props = Object.assign(props, data[className]);

      const element = createElement(this.component, props);
      const themedComponent = (
        <ThemeProvider theme={{ name: themeName(className) }}>
          {element}
        </ThemeProvider>
      );

      render(themedComponent, elem);
    });
  });
};

const themeName = (className) => {
  return "birch";
};

export default ReactMounter;
