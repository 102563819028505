import React from "react";
// @ts-expect-error - TS7016 - Could not find a declaration file for module './styles'. 'app/webpack/components/login_flow/styles.js' implicitly has an 'any' type.
import { FormContainer } from "./styles";
import { FormGroup } from "react-bootstrap";
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'components/icons/back_circle_arrow'. 'app/webpack/components/icons/back_circle_arrow.js' implicitly has an 'any' type.
import BackCircleArrow from "components/icons/back_circle_arrow";
import styled from "styled-components";
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'helpers/request'. 'app/webpack/helpers/request.js' implicitly has an 'any' type.
import request from "helpers/request";
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'utilities/flash'. 'app/webpack/utilities/flash.js' implicitly has an 'any' type.
import Flash from "utilities/flash";

import { colors } from "@grnhse/seedling/lib/azalea/constants";

type Props = {
  backCallback: () => void;
  login: string;
  magicLinksPath: string;
  magicLinkReturnTo: string;
};

const requestThrottled = 429;

const MagicLinkNotification = ({
  backCallback,
  login,
  magicLinksPath,
  magicLinkReturnTo,
}: Props) => {
  const resendMagicLink = (login: string, returnTo: string) => {
    request({
      url: magicLinksPath,
      method: "POST",
      data: {
        login: login,
        return_to: returnTo,
      },
    })
      // @ts-expect-error - TS7006 - Parameter 'request' implicitly has an 'any' type. | TS7006 - Parameter '_event' implicitly has an 'any' type.
      .then((request, _event) => {
        let response = request.data;

        if (response.notice) {
          Flash.set(response.notice, "notice", false);
        }
      })
      // @ts-expect-error - TS7006 - Parameter 'request' implicitly has an 'any' type.
      .catch((request) => {
        if (request.response.data.error) {
          Flash.setError(request.response.data.error);
        } else if (request.response.status === requestThrottled) {
          Flash.setError(
            "Sorry, you have made too many requests. Please try again later."
          );
        } else {
          Flash.setError("Sorry, we encountered an error. Please try again.");
        }
      });
  };

  return (
    <React.Fragment>
      <FormContainer>
        <h2>Sign-in link sent</h2>
        <BackToEmailFormGroup>
          <BackToEmailLink
            href="#"
            onClick={backCallback}
            id="back-to-email-form"
          >
            <BackCircleArrow title="Change Email" />

            <span>{login}</span>
          </BackToEmailLink>
        </BackToEmailFormGroup>
        <Explanation>
          We just emailed you a magic link you can use to securely sign in. It
          may take a couple of minutes to arrive.
        </Explanation>
        <ResendEmailLink
          href="#"
          onClick={() => {
            resendMagicLink(login, magicLinkReturnTo);
          }}
        >
          Resend email
        </ResendEmailLink>
      </FormContainer>
    </React.Fragment>
  );
};

const BackToEmailFormGroup = styled(FormGroup)`
  svg {
    vertical-align: middle;
    margin-right: 5px;
  }
`;

const BackToEmailLink = styled.a`
  color: ${colors.mediumGreenhouseGreen};
`;

const ResendEmailLink = styled.a`
  color: ${colors.mediumBlue};
  display: inline-block;
  margin-top: 16px;
`;

const Explanation = styled.p`
  color: ${colors.darkestGrey};
`;

export default MagicLinkNotification;
