import React from "react";
import Csrf from "utilities/csrf";

class HiddenCsrfInput extends React.Component {
  render() {
    return (
      <input type="hidden" name="authenticity_token" value={Csrf.token()} />
    );
  }
}

export default HiddenCsrfInput;
