import React from "react";
import styled from "styled-components";
import { colors } from "@grnhse/seedling/lib/azalea/constants";
import PrimaryPalette from "@grnhse/seedling/lib/birch/colors";

export const FormContainer = styled.div`
  color: ${colors.evergreen};

  h2 {
    width: 400px;
    margin: 60px 0 30px 0;
    font-family: "Untitled Serif", serif;
    font-size: 78px;
    text-align: left;
    line-height: 1.1em;

    //mobile/tablet
    @media only screen and (max-width: 980px) {
      margin-top: 50px;
      line-height: 1em;
    }

    //mobile
    @media only screen and (max-width: 480px) {
      width: 250px;
      font-size: 56px;
    }

    //shorter screens
    @media only screen and (max-height: 800px) {
      margin-top: 7vh;
      margin-bottom: 5vh;
    }
  }

  form {
    a {
      color: ${colors.mediumGreenhouseGreen};
    }
  }
`;

export const Description = styled.p`
  margin: 10px auto;
  font-size: 16px;
  letter-spacing: 0.5px;

  //mobile/tablet
  @media only screen and (max-width: 980px) {
    margin: 20px auto;
  }

  //mobile
  @media only screen and (max-width: 480px) {
    margin: 25px auto;
  }
`;

export const Input = styled.input`
  && {
    font-size: 19px;
    border: 2px solid ${colors.lightGrey};
    border-radius: 8px;
    padding: 15px;
    height: 60px;
    width: 100%;
  }
  ::placeholder {
    color: ${colors.evergreen};
  }
`;

export const StyledButton = styled.button.attrs({
  className: "button-primary",
})`
  flex: 0 0 auto;
  letter-spacing: 1px;
  padding: 10px 55px;

  //mobile
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const LongerStyledButton = styled(StyledButton)`
  //desktop/tablet
  @media only screen and (min-width: 480px) {
    && {
      width: auto;
    }
  }
`;
