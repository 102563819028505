import React from "react";
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";

import Csrf from "utilities/csrf";
import request from "helpers/request";
import PropTypes from "prop-types";
import Flash from "utilities/flash";

import styled from "styled-components";
import { FormContainer, Input, StyledButton } from "./styles";

import GoogleButtonImage from "./google_logo.svg";
import GoogleButtonImageMobile from "./google_logo_mobile.svg";
import GoogleButtonImageMobileLarge from "./google_logo_longest.svg";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: this.props.login,
      disableNextButton: false,
    };

    this.requestThrottled = 429;
  }

  onChange = (event) => {
    this.setState({
      login: event.target.value,
      disableNextButton: false,
    });
  };

  validateLogin = (event) => {
    event.preventDefault();

    request({
      url: this.props.validate_login_path,
      method: "get",
      params: {
        login: this.state.login,
      },
    })
      .then((request, _event) => {
        let response = request.data;

        if (response.disable_next_button) {
          this.setState({ disableNextButton: true });
        }

        if (response.notice) {
          Flash.set(response.notice, "notice", false);
        }

        if (response.show_password_form) {
          this.props.showPasswordForm(this.state.login, response.saml_path);
        }

        if (response.show_magic_link_notification) {
          this.props.magicLinkNotificationCallback(
            this.state.login,
            response.return_to
          );
        }

        if (response.redirect_to) {
          window.location = response.redirect_to;
        }
      })
      .catch((request) => {
        if (request.response.data.error) {
          Flash.setError(request.response.data.error);
        } else if (request.response.status === this.requestThrottled) {
          Flash.setError(
            "Sorry, you have made too many requests. Please try again later."
          );
        } else {
          Flash.setError("Sorry, we encountered an error. Please try again.");
        }
      });
  };

  render() {
    return (
      <FormContainer id="email-form">
        <h2>Welcome on board.</h2>
        <StyledForm id="validate-login" onSubmit={this.validateLogin}>
          <FormGroup controlId="email" bsSize="large">
            <Input
              type="input"
              placeholder="Email Address"
              aria-label="Email Address"
              name="email"
              value={this.state.login}
              onChange={this.onChange}
              autoFocus
              required
            />
          </FormGroup>

          <FlexContainer>
            <FormGroup>
              <StyledButton
                type="submit"
                disabled={this.state.disableNextButton}
              >
                Next
              </StyledButton>
            </FormGroup>

            <FormGroup>
              <Separator>or</Separator>
            </FormGroup>

            <FormGroup>
              <GoogleButton
                type="submit"
                form="google-sign-in"
                aria-label="Sign In With Google"
              />
            </FormGroup>
          </FlexContainer>
        </StyledForm>
        <form
          action={this.props.sign_in_with_google_path}
          id="google-sign-in"
          method="post"
        >
          <input type="hidden" value={Csrf.token()} name="authenticity_token" />
        </form>
      </FormContainer>
    );
  }
}

const StyledForm = styled.form`
  margin: 60px 0 0;

  //shorter screens
  @media only screen and (max-height: 800px) {
    margin-top: 0;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
  width: 100%;

  //mobile
  @media only screen and (max-width: 480px) {
    display: block;
  }
`;

const Separator = styled.span`
  font-size: 20px;
  margin: 0 20px;

  //mobile
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

const GoogleButton = styled.button`
  // reset styles
  all: initial;
  cursor: pointer;

  &&:active,
  &&:hover {
    background-color: unset;
  }

  width: 193px;
  height: 48px;
  background-image: url("${GoogleButtonImage}");
  background-size: contain;
  background-repeat: no-repeat;

  //mobile
  @media only screen and (max-width: 480px) {
    width: 100%;
    height: 0;
    padding-top: 14.2414860681%; // logo width/height = 46px/323px
    background-image: url("${GoogleButtonImageMobileLarge}");
  }

  //smaller mobile
  @media only screen and (max-width: 340px) {
    width: 100%;
    height: 0;
    padding-top: 17.62452107%; // logo width/height = 46px/261px
    background-image: url("${GoogleButtonImageMobile}");
  }
`;

LoginForm.propTypes = {
  login: PropTypes.string,
  validate_login_path: PropTypes.string.isRequired,
  sign_in_with_google_path: PropTypes.string.isRequired,
  showPasswordForm: PropTypes.func.isRequired,
  magicLinkNotificationCallback: PropTypes.func.isRequired,
};

export default LoginForm;
