import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";
import { Flash as _Flash } from "components/shared/flash";

// Methods for adding flash notices and errors to the page. All add methods accept either
// a string or an array of strings.
const Flash = {
  remove(selector) {
    ReactDOM.unmountComponentAtNode(
      document.getElementsByClassName("flash-wrapper")[0]
    );
  },

  setError: function (object, withDelay = false) {
    if (withDelay) {
      setTimeout(() => Flash.set(object, "error", true), 500);
    } else {
      Flash.set(object, "error", true);
    }
  },

  setPending: function (object) {
    Flash.set(object, "pending", true);
  },

  setSuccess: function (object) {
    Flash.set(object, "success", true);
  },

  set: function (object, type) {
    Flash.remove();
    if (object instanceof Array) {
      $.each(object, function (i, message) {
        addSingleFlash(message);
      });
    } else {
      addSingleFlash(object);
    }

    function addSingleFlash(message) {
      ReactDOM.render(
        <_Flash type={type} message={message} />,
        document.getElementsByClassName("flash-wrapper")[0]
      );
    }
  },
};

export default Flash;
