import Flash from "utilities/flash";
import Csrf from "utilities/csrf";
import axios from "axios";
import Qs from "qs";

const request = axios.create({
  headers: {
    "X-CSRF-Token": Csrf.token(),
  },

  paramsSerializer: function (params) {
    return Qs.stringify(params, { arrayFormat: "brackets" });
  },
});

// This Header is used to determine if a request is XHR or not.
// This Header is not set by default for axios
request.defaults.headers.common["X-Requested-With"] = "XmlHttpRequest";

request.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // No need to display a flash when response has validation errors;
    // These errors are handled directly at each invalid form input;
    if (
      !!error.response &&
      !!error.response.data &&
      !error.response.data.validation_errors
    ) {
      Flash.setError("Sorry, we encountered an error. Please try again.");
    }
    return Promise.reject(error);
  }
);

request.all = axios.all;

export default request;
