import React from "react";
import PropTypes from "prop-types";

const BackCircleArrow = ({ title, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label={title}
  >
    <title>{title}</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-828.000000, -501.000000)"
        fill="#008561"
        fillRule="nonzero"
      >
        <g transform="translate(826.722222, 500.000000)">
          <g transform="translate(10.500000, 10.500000) rotate(-180.000000) translate(-10.500000, -10.500000) translate(0.000000, 0.500000)">
            <g transform="translate(0.214365, 0.000000)">
              <path d="M9.66744208,19.3174653 C8.41546478,19.3174653 7.19397041,19.080134 6.03561924,18.609826 C4.83807574,18.1242765 3.7646413,17.4101051 2.84362148,16.4912627 C1.92260166,15.5702429 1.21060761,14.4968084 0.725058156,13.2992649 C0.254750163,12.1409137 0.0174188146,10.9194194 0.0174188146,9.66744208 C0.0174188146,8.41546478 0.254750163,7.19397041 0.725058156,6.03561924 C1.21060761,4.83807574 1.92477901,3.7646413 2.84362148,2.84362148 C3.76246394,1.92260166 4.83807574,1.21060761 6.03561924,0.725058156 C7.19397041,0.254750163 8.41546478,0.0174188146 9.66744208,0.0174188146 C10.9194194,0.0174188146 12.1409137,0.254750163 13.2992649,0.725058156 C14.4968084,1.21060761 15.5702429,1.92477901 16.4912627,2.84362148 C17.4122825,3.7646413 18.1242765,4.83807574 18.609826,6.03561924 C19.0779566,7.19179306 19.3174653,8.41328743 19.3174653,9.66744208 C19.3174653,10.9215967 19.080134,12.1409137 18.609826,13.2992649 C18.1242765,14.4968084 17.4101051,15.5702429 16.4912627,16.4912627 C15.5702429,17.4122825 14.4968084,18.1242765 13.2992649,18.609826 C12.1409137,19.080134 10.9194194,19.3174653 9.66744208,19.3174653 Z M9.66744208,1.32382991 C7.44001117,1.32382991 5.34322136,2.19259328 3.768996,3.76681865 C2.19259328,5.34322136 1.32382991,7.44001117 1.32382991,9.66744208 C1.32382991,11.894873 2.19259328,13.9916628 3.76681865,15.5658882 C5.34322136,17.1422909 7.43783381,18.0088769 9.66526473,18.0088769 C11.8926956,18.0088769 13.9894854,17.1401135 15.5637108,15.5658882 C17.1379362,13.9916628 18.0110542,11.894873 18.0110542,9.66744208 C18.0110542,7.44001117 17.1422909,5.34322136 15.5680655,3.768996 C13.9916628,2.19259328 11.894873,1.32382991 9.66744208,1.32382991 Z"></path>
              <polygon points="8.21732577 14.414069 7.29195124 13.4908719 11.115381 9.66744208 7.29195124 5.84401228 8.21732577 4.92081511 12.9639527 9.66744208"></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

BackCircleArrow.propTypes = {
  title: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

BackCircleArrow.defaultProps = {
  title: "",
  height: "20px",
  width: "20px",
};

export default BackCircleArrow;
