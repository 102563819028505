import React from "react";
import PropTypes from "prop-types";
import {
  SuccessFlash,
  LoadingFlash,
  ErrorFlash,
} from "@grnhse/seedling/lib/birch/components/flash";

const Flash = ({ type, message }) => {
  if (type === "success" || type === "notice") {
    return <SuccessFlash message={message} />;
  } else if (type === "error" || type === "errors" || type === "alert") {
    return <ErrorFlash message={message} />;
  } else {
    return <LoadingFlash message={message} />;
  }
};

Flash.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

export { Flash };
